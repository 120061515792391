import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "propertyMapWrapper block lg:flex h-full lg:h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "sidebar ios-fix"
}
const _hoisted_3 = { class: "properties-sidebar-wrapper" }
const _hoisted_4 = { class: "properties-sidebar" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "text-2xl md:text-3xl lg:mx-auto mb-0-important" }
const _hoisted_8 = { class: "absolute bottom-0 left-0 pr-4 pb-2 cursor-pointer" }
const _hoisted_9 = {
  key: 2,
  class: "swiperWrapper"
}
const _hoisted_10 = { class: "absolute bottom-0 left-0 pr-4 pb-2 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonButton = _resolveComponent("IonButton")!
  const _component_property_card = _resolveComponent("property-card")!
  const _component_AppMap = _resolveComponent("AppMap")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMinLarge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_IonButton, {
                      fill: "clear",
                      color: "dark",
                      class: "px-0 -ml-4",
                      size: "small",
                      onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('goBack'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IonIcon, {
                          icon: _ctx.arrowBack,
                          size: "large"
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }),
                    _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.t("immobilie.kartenAnsicht")) + " (" + _toDisplayString(_ctx.properties.length) + ") ", 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.t("immobilie.aktualisiertAm")) + ": " + _toDisplayString(_ctx.lastFetch), 1)
                ]),
                _renderSlot(_ctx.$slots, "default"),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (item) => {
                  return (_openBlock(), _createBlock(_component_property_card, {
                    key: item.id,
                    property: item,
                    class: _normalizeClass([_ctx.hoveredProp && _ctx.hoveredProp.id === item.id ? 'hovered' : '', "w-full max-w-md m-0 mb-4"]),
                    "custom-click-function": () => _ctx.cardClicked(item)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_IonButton, {
                          class: "cardButton",
                          size: "small",
                          fill: "flat",
                          onClick: ($event: any) => ($event.stopPropagation(), _ctx.openProperty(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("immobilie.oeffnen")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["property", "class", "custom-click-function"]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isViewEntered)
      ? (_openBlock(), _createBlock(_component_AppMap, {
          key: 1,
          identifier: "propertyMap",
          "map-settings": _ctx.currentProject.mapConfig.map,
          style: {"width":"100%"},
          class: "h-full lg:h-screen",
          "onMap:loaded": _ctx.handleMapLoaded,
          "onMap:zoomOverview": _cache[1] || (_cache[1] = ($event: any) => (_ctx.propertyMap.zoomToOverview(_ctx.currentProject.mapConfig.map)))
        }, null, 8, ["map-settings", "onMap:loaded"]))
      : _createCommentVNode("", true),
    (!_ctx.isMinLarge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.propertySlideList.length > 0)
            ? (_openBlock(), _createBlock(_component_swiper, {
                key: 0,
                ref: "propertySwiper",
                modules: _ctx.swiperModules,
                "slides-per-view": 1,
                "centered-slides": true,
                "space-between": 15,
                navigation: true,
                onSlideChange: _ctx.onSlideChange,
                onAfterInit: _ctx.onSwiperInit
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertySlideList, (item, i) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, { key: i }, {
                      default: _withCtx(() => [
                        _createVNode(_component_property_card, {
                          property: item,
                          "is-slide-mode": "",
                          class: "tinycard ml-12 mr-12",
                          "custom-click-function": () => _ctx.cardClicked(item)
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_IonButton, {
                                class: "cardButton",
                                size: "small",
                                fill: "flat",
                                onClick: ($event: any) => ($event.stopPropagation(), _ctx.goToDetailPage(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("immobilie.oeffnen")), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["property", "custom-click-function"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules", "onSlideChange", "onAfterInit"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}