<template>
  <div class="propertyMapWrapper block lg:flex h-full lg:h-screen">
    <div
      v-if="isMinLarge"
      class="sidebar ios-fix"
    >
      <div>
        <div class="properties-sidebar-wrapper">
          <div class="properties-sidebar">
            <div class="mb-4">
              <div class="flex items-center">
                <IonButton
                  fill="clear"
                  color="dark"
                  class="px-0 -ml-4"
                  size="small"
                  @click="() => $emit('goBack')"
                >
                  <IonIcon
                    :icon="arrowBack"
                    size="large"
                  />
                </IonButton>
                <h1 class="text-2xl md:text-3xl lg:mx-auto mb-0-important">
                  {{ t("immobilie.kartenAnsicht") }} ({{ properties.length }})
                </h1>
              </div>
              <div>{{ t("immobilie.aktualisiertAm") }}: {{ lastFetch }}</div>
            </div>

            <slot />

            <property-card
              v-for="item in properties"
              :key="item.id"
              :property="item"
              :class="hoveredProp && hoveredProp.id === item.id ? 'hovered' : ''"
              class="w-full max-w-md m-0 mb-4"
              :custom-click-function="() => cardClicked(item)"
            >
              <div class="absolute bottom-0 left-0 pr-4 pb-2 cursor-pointer">
                <IonButton 
                  class="cardButton"
                  size="small"
                  fill="flat" 
                  @click="$event.stopPropagation(), openProperty(item)"
                >
                  {{ t("immobilie.oeffnen") }}
                </IonButton>
              </div>
            </property-card>
          </div>
        </div>
      </div>
    </div>

    <AppMap
      v-if="isViewEntered"
      identifier="propertyMap"
      :map-settings="currentProject.mapConfig.map"
      style="width: 100%;"
      class="h-full lg:h-screen"
      @map:loaded="handleMapLoaded"
      @map:zoom-overview="propertyMap.zoomToOverview(currentProject.mapConfig.map)"
    />
    
    <div
      v-if="!isMinLarge"
      class="swiperWrapper"
    >
      <swiper
        v-if="propertySlideList.length > 0"
        ref="propertySwiper"
        :modules="swiperModules"
        :slides-per-view="1"
        :centered-slides="true"
        :space-between="15"
        :navigation="true"
        @slide-change="onSlideChange"
        @after-init="onSwiperInit"
      >
        <swiper-slide
          v-for="(item, i) in propertySlideList"
          :key="i"
        >
          <property-card
            :property="item"
            is-slide-mode
            class="tinycard ml-12 mr-12"
            :custom-click-function="() => cardClicked(item)"
          >
            <div class="absolute bottom-0 left-0 pr-4 pb-2 cursor-pointer">
              <IonButton 
                class="cardButton"
                size="small"
                fill="flat" 
                @click="$event.stopPropagation(), goToDetailPage(item)"
              >
                {{ t("immobilie.oeffnen") }}
              </IonButton>
            </div>
          </property-card>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { IonButton, IonIcon } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
//Composables
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import { usePropertyMap } from "../../composables/usePropertyMap";
//Models
import Property from "@/models/immobilie.model";
import { getLocalMetaData } from "@/models/local-meta-data";
//Components
import AppMap from '@/components/v2/App/AppMap.vue';
import PropertyCard from "./PropertyCard.vue";
//Other
import _ from 'lodash';
import moment from "moment";
//Swiper
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default defineComponent({
  name: "PropertiesMap",
  components: {
    IonButton,
    IonIcon,
    AppMap,
    PropertyCard,
    Swiper,
    SwiperSlide
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
    isViewEntered: {
      type: Boolean,
      required: true
    }
  },
  emits: ['goBack'],
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const propertyMap = usePropertyMap(props.properties);
    const hoveredProp = ref();
    const router = useRouter();
    const propertySlideList = ref([] as any[]);
    const currentProject = computed(() => store.state.user.currentUserProject);
    const propertySwiper = ref();
    const isMapLoaded = ref(false);
    const isSwiperLoaded = ref(false);

    const debouncedFctn = _.debounce(() => {    
      // changesLoading variable caused flickering of object card (show on/off/on/off,...)
      propertySlideList.value = JSON.parse(JSON.stringify(props.properties));
    }, 600)

    watch(() => props.properties, (newVal) => {
        // this code is called when reloading map, but not when entering map from übersicht (-> no slide list)
        debouncedFctn();
        propertyMap.updateProperties(newVal);
      }
    )

    // this is called when entering map and when reloading map,
    // so it is called twice when reloading (here and in watcher), but executed once due to debounce
    debouncedFctn();

    const { isMinLarge } = useScreenSize();

    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData('lastBackendFetch'));
      return `${date.format('DD.MM.YYYY')} um ${date.format('HH:mm:ss')}`
    });

    const cardClicked = (prop: any) => {
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }
    // desktop view
    const openProperty = (prop: any) => {
      const routeData = router.resolve({ path: `/property/${prop.id }` });
      window.open(routeData.href, '_blank');
    }
    // mobile view
    const goToDetailPage = (prop: any) => {
      router.push({
        path: `/property/${prop.id}`
      })
    }
    const onSlideChange = (e: any) => {
      const prop = propertySlideList.value[e.activeIndex];
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }    
    const onSwiperInit = (e: any) => {
      isSwiperLoaded.value = true;
      if(isMapLoaded.value){
        flyToSlide(e.activeIndex);
      }
    }
    const flyToSlide = (goToIndex: any) => {      
      if(hoveredProp.value && propertySwiper.value){     // propertySwiper is undefined here    
        // object is selected -> set slider to this object
        const propIndex = propertySlideList.value.findIndex((val) => {return val.id === hoveredProp.value.id});
        propertySwiper.value?.slideTo(propIndex);

      }else{ // no object selected -> fly to given index
        const prop = propertySlideList.value[goToIndex];
        hoveredProp.value = prop;
        propertyMap.animateToProperty(prop);
      }
    }

    const handleMapLoaded = (map: any) => {
      propertyMap.generateMap(map, currentProject.value.mapConfig.map);
      isMapLoaded.value = true;
      if(isSwiperLoaded.value){
        flyToSlide(0);
      }
    }

    return {
      currentProject,
      t,
      isMinLarge,
      lastFetch,
      cardClicked,
      hoveredProp,
      arrowBack,
      openProperty,
      propertySlideList,
      swiperModules: [Navigation],
      handleMapLoaded,
      onSlideChange,
      onSwiperInit,
      propertyMap,
      goToDetailPage
    }
  }
});
</script>

<style lang="scss">
.propertyMapWrapper {
  .swiperWrapper {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;

    .swiper {
      .swiper-wrapper {
        margin: 0px auto;

        .swiper-slide {
          ion-card {
            margin: 0 auto !important;
          }
        }
      }

      .swiper-button-next, .swiper-button-prev {
        color: var(--primary);
      }
    }
  }

  .sidebar {
    border-right: 1px solid #9f9f9f;
  }

  .properties-sidebar-wrapper {
    width: 500px;
    background: #f3f5f9;
    overflow: auto;
    padding-right: 24px;
    padding-left: 24px;
    height: 100vh;
    overflow-y: scroll;
  }
  .properties-sidebar {
    padding-bottom: 24px;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .hovered {
    border: 1px solid black;
  }

  @media (max-width: 400px) {
    .property-card {
      width: 300px;
    }
  }

  .ios-fix {
    padding-top: unquote('constant(safe-area-inset-top))') !important;
    padding-top: env(safe-area-inset-top) !important;
  }

  @media (max-width: 1024px) {
    #propertyMap {
      width: 100vw !important;
    }
  }
  .tinycard {
    max-width: 18rem;
  }
}
</style>
