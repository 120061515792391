<template>
  <IonCard
    class="propertyCard"
    @click="cardClicked"
  >
    <AImage
      :image-class="'w-full object-cover md:block '+ (isSlideMode?'h-20':'h-44')"
      :src="getPhotoUrl(property.vorschaubild, { small: true })"
      set-to-max
    />
    <IonCardHeader :class="isSlideMode?'smallerHeader':''">
      <IonCardSubtitle>
        {{ property.strasse }} {{ property.plz }} {{ property.stadt }}
      </IonCardSubtitle>
      <IonCardTitle :class="isSlideMode?'smallerTitle':''">
        {{ property.externeObjektNr }}
      </IonCardTitle>
    </IonCardHeader>

    <IonCardContent>
      <div class="flex flex-row items-center justify-end gap-4 mini">        
        <slot />        
        <div 
          v-if="isLocateButtonEnabled"
          class="flex cursor-pointer"
          :title="$t('immobilie.redirectToMap')"  
          @click="$event.stopPropagation(); redirectToMap({ lon: property.geolocationLon, lat: property.geolocationLat })"
        >
          <IonIcon
            size="large"
            :icon="locationOutline"
          />
        </div>
        <div
          v-if="featureFlags?.property?.changeStatus"
          class="flex cursor-pointer"
          :title="$t('immobilie.updateStatus')"  
          @click="$event.stopPropagation(); changeStatus(property, t)"
        >
          <IonIcon
            size="large"
            :icon="statusIconState"
          />
        </div>
        <div
          v-if="featureFlags?.property?.downloadProperty"
          :title="$t('downloadSurveyButton.download')"
          class="flex cursor-pointer"
          @click="$event.stopPropagation(), handleImmobilieDownload()"
        >
          <IonIcon
            size="large"
            :icon="downloadIconState"
          />
        </div>
        <div
          v-if="featureFlags?.property?.changePerson"
          class="flex personIcon cursor-pointer"
          :title="$t('immobilie.updatePerson')"
          @click="$event.stopPropagation(), changePerson(property, t)"
        >
          <div v-if="property.verwalters && property.verwalters[0] && property.verwalters[0].profilbild">
            <IonImg :src="getPhotoUrl(property.verwalters[0].profilbild)" />
          </div>
          <div v-else>
            {{ username }}
          </div>
        </div>
        <div
          class="flex cursor-pointer"
          @click="$event.stopPropagation(), handlePropertyShare( property.id, t )"
        >
          <IonIcon
            size="large"
            :icon="shareSocialOutline"
          />
        </div>
      </div>
    </IonCardContent>
  </IonCard>
</template>

<script lang="ts">
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonImg, toastController, useIonRouter, IonButton } from '@ionic/vue';
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
//Models
import { ImmobilieStatus } from "@/models/immobilie/interfaces/IImmobilie";
import { default as Immobilie, default as Property } from "../../models/immobilie.model";
//Composables
import { useProperties } from "@/composables/Property/useProperties";
import useUser from "@/composables/useUser";
//Components
import AImage from "@/components/Base/AImage.vue";
//Icons
import { archiveOutline, arrowDownCircle, arrowDownCircleOutline, checkmarkCircleOutline, checkmarkDoneCircleOutline, ellipseOutline, playCircleOutline, timeOutline, shareSocialOutline, locationOutline } from "ionicons/icons";
//Other
import { getPhotoUrl } from "@/utilities/get-media-url";
import { useStore } from '@/composables/useTypedStore';
import { AppPlatform } from '@/composables/useAppInfo';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { AppLauncher } from '@capacitor/app-launcher';
import useDownloadHelper from "@/composables/useDownloadHelper";

export default defineComponent({
  name: "PropertyCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonImg,
    IonIcon,
    AImage,
    IonButton
  },
  props: {
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
    customClickFunction: {
      type: Function,
      default: undefined
    },
    isSlideMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useIonRouter()
    const { t } = useI18n({ useScope: 'global' })
    const { user } = useUser()
    const store = useStore()
    const networkConnected = computed(() => store.state.app.networkConnected);
    const { isMobile } = useDownloadHelper();
    const { downloadImmobilie, changePerson, changeStatus, generatePropertyShareLink, redirectToMap, handlePropertyShare } = useProperties()
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);

    const goToDetailPage = () => {
      router.push({
        path: `/property/${props.property.id}`
      })
    }

    const cardClicked = () => {
      if (props.customClickFunction) {
        props.customClickFunction()
        return
      }
      goToDetailPage()
    }

    const isLocateButtonEnabled = computed(() => {
      return featureFlags.value?.redirectToMap && props.property.geolocationLat && props.property.geolocationLon && ( isMobile.value || networkConnected.value );
    })

    

    return {
      goToDetailPage,
      cardClicked,
      downloadImmobilie, changePerson, changeStatus, shareSocialOutline,
      t,
      router,
      getPhotoUrl,
      user,
      featureFlags: computed(() => user.value?.organisation?.featureFlags),
      generatePropertyShareLink,
      appPlatform: computed(() => store.state.app.appPlatform),
      redirectToMap,
      isLocateButtonEnabled,
      locationOutline,
      handlePropertyShare
    }
  },
  data() {
    return {
      propertyState: null as any
    }
  },
  computed: {
    statusIconState() {
      switch(this.property.status) {
        case ImmobilieStatus.ANGELEGT: return ellipseOutline;
        case ImmobilieStatus.GEPLANT: return timeOutline;
        case ImmobilieStatus.IN_DURCHFUEHRUNG: return playCircleOutline;
        case ImmobilieStatus.ABGESCHLOSSEN: return checkmarkCircleOutline;
        case ImmobilieStatus.FREIGEGEBEN: return checkmarkDoneCircleOutline;
        case ImmobilieStatus.ARCHIVIERT: return archiveOutline;
        default: return timeOutline
      }
    },
    downloadIconState() {
      if(this.propertyState === 'OfflineAvailable') {
        return arrowDownCircle
      } else {
        return arrowDownCircleOutline
      }
    },
    username() {
      const name = this.property.verwalters && this.property.verwalters[0] && this.property.verwalters[0].username ? this.property.verwalters[0].username : ''
      const words = name.split(' ')
      const firstLetters = words.map((word: string) => word[0])
      return firstLetters.join('')
    }
  },
  created() {
    this.checkPropertyState()
  },
  methods: {
    async checkPropertyState() {
      const downloadedProperties = await Immobilie.dispatch("loadPersistedProperties")
      if(downloadedProperties?.filter((item: any) => item.id === this.property.id).length > 0) {
        this.propertyState = 'OfflineAvailable'
      } else {
        this.propertyState = 'OnServerAvailable'
      }
    },

    async handleImmobilieDownload() {
      if(this.propertyState === 'OfflineAvailable') {
        await this.removeImmobilieFromLocal()
      } else {
        await this.downloadImmobilie(this.property.id)
      }

      this.checkPropertyState()
    },

    async removeImmobilieFromLocal() {
      const im = Immobilie.find(this.property.id)
      if(!im) return

      im.isDownloaded = false
      // await Immobilie.dispatch('removeFromPersistedProperties', im)
      await Immobilie.dispatch('$deleteFromLocal', im.id)
      await Immobilie.insertOrUpdate({ data: im as any })
    },
  }
});
</script>

<style lang="scss">
ion-card.propertyCard {
  --background: var(--white100)
}

.personIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #efefef;
  border-radius: 99px;
  overflow: hidden;
}
.mini {
  min-height: 1.75rem;
}
.smallerTitle {
  margin-top: 4px;
  font-size: 18px;
}
.smallerHeader{
  padding: 8px;
}
</style>